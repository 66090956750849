$(document).on('turbolinks:load', function () {

    $('#profession-title-input').autocomplete({
        source: '/professions/suggest',
        delay: 300,
        select: function (event, ui) {
            $('#profession-form-id-field').val(ui.item.id);
            //$('#profession-form')[0].dispatchEvent(new Event('submit', {bubbles: true}));
            Rails.fire($('#profession-form')[0], 'submit')
        }
    }).on('keypress', function (e) {
        var keyCode = e.keyCode || e.which;
        if (keyCode === 13) {
            if ($(this).val().length > 0) {
                $('#professions-search-form-term').val($(this).val());
                //$('#professions-search-form')[0].dispatchEvent(new Event('submit', {bubbles: true}));
                Rails.fire($('#professions-search-form')[0], 'submit')
                $(this).autocomplete('close');
            }
            e.preventDefault();
            return false;
        }
    });

    $('.similarites-info-toggle[data-toggle="popover"]').popover({html: true});

    var t_matches = $('#t_matches').DataTable({
        searching: true,
        paging: true,
        sorting: false,
        pageLength: 10,
        lengthChange: false,
        info: false,
        columns: [
            null,
            {'visible': $('#match_result_show_scores').html() === 'true'},
            {'visible': false},
            {'visible': false},
            {'visible': false}
        ],
        drawCallback: function(settings) {
            var pagination = $(this).closest('.dataTables_wrapper').find('.dataTables_paginate');
            pagination.toggle(this.api().page.info().pages > 1);

            var recordCount = this.api().page.info().recordsDisplay;
            if (recordCount != 1) {
                $('#match_result_count').html($('#match_result_count_template').html().replace("{{count}}", recordCount));
            } else {
                $('#match_result_count').html($('#match_result_count_template_one_match').html());
            }
        }
    });

    $("#search_filter").keyup(function () {
        return t_matches.column(2).search($(this).val()).draw();
    });

    $('#education').change(function () {
        return t_matches.column(3).search($(this).val()).draw();
    });

    $('#occupationclass').change(function () {
        return t_matches.column(4).search($(this).val()).draw();
    });

});
