$(document).on('turbolinks:load', function () {
    $("#upload_cv_file_field").change(function() {
        $('#cv_upload_form').submit();
    });

    $('#micro_cv').focus(function() {
        $('#micro_cv').addClass('micro_cv_expanded', 500);
        $('#micro_cv_text').html('&nbsp;');
        $("#micro_cv").attr("placeholder", $('#micro_cv_instructions_string').html());
    });

    $('#micro_cv').bind('input propertychange', function() {
        if (this.value.length >= 10 && !$("#micro_cv_submit_button").is(":visible")) {
            $("#micro_cv_submit_button").css("display", "block");
            $("#micro_cv_submit_button").switchClass('submit_hidden', 'submit_shown', 400);
        } else if (this.value.length < 10 && $("#micro_cv_submit_button").is(":visible")) {
            $("#micro_cv_submit_button").switchClass('submit_shown', 'submit_hidden', 400, function() {
                $("#micro_cv_submit_button").css("display", "none");
            });
        }
    });

    //verify terms and conditions checked

    $('.link_with_terms').click(function(e) {
        var parent = $(this).closest('.link_with_terms_wrapper');
        if(!parent.find('.signup_terms_checkbox:checked').length && parent.find('.signup_terms_checkbox').length) {
            parent.find('.signup_terms_error').show();
            e.preventDefault();
        } else {
            parent.find('.signup_terms_error').hide();
        }
    });

});